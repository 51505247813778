@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap");

@import "./colorvariable.scss";
@import "./bootstrap.scss";
@import "./floating-labels.scss";

:root {
    --gray-hue: 190;
    --blue-hue: 209;
    --purple-hue: 225;
    --red-hue: 0;
    --yellow-hue: 56;
    --green-hue: 131;
    --main-hue: var(--gray-hue);
    // themeChanger
    --bg-dark-blue: #204f8d;
    --blue-primary: #034aa6;
    --themeBlue: #488bff;
    --label-color-blue: #4f92ea;
    --label-color-dark-blue: #056cf2;
    --borderTop-color: #0959c1;
    --borderBottom-color: #0959c1;
    width: 100%;
}
:host {
    font-family: "Montserrat", sans-serif;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: initial;
    font-family: "Noto Sans", "Montserrat", sans-serif;
    font-size: 13px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-corner {
    width: 5px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(255, 255, 255, 0);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--label-color-blue);
    -webkit-box-shadow: inset 0 0 2px rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: var(--label-color-blue);
}
body {
    font-family: "Nunito", sans-serif;
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 1em;
    background: white;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.form-control {
    padding: 0.1rem 0.75rem;
}

// Custom Select Option
.select-filter {
    border-color: #b7d6ff;
    padding: 0.5em 3.5em 0.5em 1em;
    color: gray;
    height: 3.2em;
    background-color: white;
    border-radius: 0.438em;
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-flex-item-align: center !important;
    align-self: center !important;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // Create Background and White Arrow
    background-image: linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(
            to right,
            var(--label-color-dark-blue),
            var(--label-color-dark-blue)
        );

    // Positioning Background and White Arrow
    background-position: calc(100% - 1.4em) calc(1em + 0.071em),
        calc(100% - 1em) calc(1em + 0.071em), 100% 0;
    // Sizing White Arrow
    background-size: 0.429em 0.429em, 0.429em 0.429em, 3em 4em;
    background-repeat: no-repeat;
}

.select-filter:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        linear-gradient(
            to right,
            var(--label-color-dark-blue),
            var(--label-color-dark-blue)
        );
    background-position: calc(100% - 1em) 1em, calc(100% - 1.4em) 1em, 100% 0;
    background-size: 0.429em 0.429em, 0.429em 0.429em, 2.8em 4em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
}

.select-filter-sm {
    border-color: #b7d6ff;
    color: gray;
    padding: 0.5em 3.5em 0.5em 1em;
    border-radius: 6px;
    display: inline-block;
    line-height: 1.5em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // Create Background and White Arrow
    background-image: linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(
            to right,
            var(--label-color-dark-blue),
            var(--label-color-dark-blue)
        );

    // Positioning Background and White Arrow
    background-position: calc(100% - 1.3em) calc(0.8em + 0.32em),
        calc(100% - 1em) calc(0.8em + 0.32em), 100% 0;
    // Sizing White Arrow
    background-size: 0.321em 0.321em, 0.321em 0.321em, 2.9em 2.7em;
    background-repeat: no-repeat;
}

.select-filter-sm:focus {
    // Create Background and White Arrow
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        linear-gradient(
            to right,
            var(--label-color-dark-blue),
            var(--label-color-dark-blue)
        );

    // Positioning Background and White Arrow
    background-position: calc(100% - 1em), calc(100% - 1.3em), 100% 0;
    // Sizing White Arrow
    background-size: 0.321em 0.321em, 0.321em 0.321em, 2.9em 2.7em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
}

.select-filter:disabled {
    font-weight: 500;
    background-color: white;
    background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
        linear-gradient(135deg, #bec4cf 50%, transparent 50%),
        linear-gradient(to right, #f2f7ff, #f2f7ff);
}

.select-filter-sm:disabled {
    font-weight: 500;
    background-color: white;
    background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
        linear-gradient(135deg, #bec4cf 50%, transparent 50%),
        linear-gradient(to right, #f2f7ff, #f2f7ff);
}

.select-filter-sm-disabled {
    border: 1px solid #bec4cf;
    border-radius: 6px;
    display: inline-block;
    line-height: 1.5em;
    height: 31px;
    padding: 0.5em 3.5em 0.5em 1em;
    margin-left: 10px;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: -5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // Create Background and White Arrow
    background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
        linear-gradient(135deg, #bec4cf 50%, transparent 50%),
        linear-gradient(to right, #f2f7ff, #f2f7ff);

    // Positioning Background and White Arrow
    background-position: calc(100% - 13px) calc(0.8em + 2px),
        calc(100% - 8px) calc(0.8em + 2px), 100% 0;
    // Sizing White Arrow
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
}

.select-filter-disabled {
    border: 1px solid $select-filter-border;
    border-radius: 7px;
    display: inline-block;
    line-height: 1.5em;
    height: 38px;
    padding: 0.5em 3.5em 0.5em 1em;
    margin-left: 10px;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: -5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    color: #676869;

    // Create Background and White Arrow
    background-image: linear-gradient(45deg, transparent 50%, #bec4cf 50%),
        linear-gradient(135deg, #bec4cf 50%, transparent 50%),
        linear-gradient(to right, #f2f7ff, #f2f7ff);

    // Positioning Background and White Arrow
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 14px) calc(1em + 2px), 100% 0;
    // Sizing White Arrow
    background-size: 6px 6px, 6px 6px, 3em 3em;
    background-repeat: no-repeat;
}

.select-filter-disabled:disabled {
    background-color: #ffffff;
    color: #bec4cf;
}

.select-filter-sm-disabled:disabled {
    background-color: #ffffff;
}

.display-none {
    display: none !important;
}

.floatLeft {
    float: left;
}
.mrgntp-m5 {
    margin-top: -4.5em;
}

.pageHeading {
    z-index: 1;
    margin-top: -8px;
    font-size: 30px;
}

.border-heading {
    border-bottom: 2px solid #e3edfd;
    margin-bottom: 20px;
}

// margin 10px

.mb-10 {
    margin-bottom: 10px;
}
.mr-10 {
    margin-right: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.ml-10 {
    margin-left: 10px;
}

.filter-label {
    color: $label-color-gray;
    font-weight: 700;
}

.exam-type {
    white-space: nowrap;
}

thead th {
    padding: 0.8em 0.5em;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -17px;
}

thead .msa-header {
    padding: 0.8em 0.5em;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 15px;
}

.index-style {
    background-color: $bg-container;
}

.icon-width {
    width: 32px;
    padding: 5px;
}

.noPad {
    padding: 0 !important;
}
.nav-tabs {
    border-bottom: none;
}

.table-bordered td,
.table-bordered th {
    border-color: #b7d6ff;
}
.table-workbook {
    background-color: $bg-light;
    & thead {
        & th:nth-child(even) {
            background: $bg-White;
        }
    }
    // ------------------------
    & tbody {
        & th:nth-child(odd) {
            background: $bg-White;
        }
    }
    & tbody {
        & td:nth-child(odd) {
            background: $bg-White;
        }
    }
}

.data-block {
    padding: 3px;
    background: #fff;
    border: 1px solid #b7d6ff;
    border-radius: 3px;
    text-align: center;
}

.w-8 {
    width: 8%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}
.mt-50 {
    margin-top: -50px;
}

.pl-0 {
    padding-left: 0;
}

.font-medium {
    font-weight: 600;
}

.btn-switch {
    margin-top: 0.7em;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
    float: left;
    width: max-content;
    float: right;
    background: transparent;
    color: #fff;
    border-radius: 2.5em;
    box-shadow: 0 0 0.25em 0px #e9e9e9;
    & li {
        display: flex;
        align-items: center;
        line-height: 2.3rem;
        justify-content: center;
        padding: 6px 16px;
        border-radius: 2.5em;
        text-transform: capitalize;
        text-decoration: none;
        float: right;
        font-weight: 600;
        font-size: 1em;
        &.active {
            background: #fff;
            color: var(--blue-primary);
        }
    }
}

// ===========================================================
.pickr {
    border: 1px solid #e6f0ff;
    padding: 0.5em;
    border-radius: 0.5em;
    top: 1.4em;
}

.upload-book-title {
    font-size: 1em;
    font-weight: 500;
    background: #e6f0ff;
    padding: 9px;
    border-radius: 1em;
    position: absolute;
    top: -15px;
    color: #676869;
}

.light-gray {
    color: #bec4cf;
}

#switch {
    height: 0;
    width: 0;
    visibility: hidden;
}

.pcr-app.visible {
    left: 64em !important;
}

.toggle-switch {
    cursor: pointer;
    text-indent: -9999px;
    width: 25px;
    height: 18px;
    background: #bec4cf;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-top: -12px;
    margin-bottom: 0;
}

.toggle-switch:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 2px;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input:checked + .toggle-switch {
    background: #1abf73;
}

input:checked + .toggle-switch:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-switch:active:after {
    width: 20px;
}
// ===========================================================

::-webkit-file-upload-button {
    background: #e6f0ff;
    color: #676869;
    padding: 0.8em;
    border: 1px solid #e6f0ff;
}

.no-pad {
    padding: 0;
}
.no-mar {
    margin: 0;
}
.no-border {
    border: none;
}

.text-primary {
    color: var(--bg-dark-blue) !important;
}

.workbook-dropdown .dropdown-item {
    background: $bg-light;
    font-size: 1.875em;
    border-bottom: 0.063em solid #b7d6ff;
    padding: 0.625em 0.313em;
    color: $bg-blue;
    width: 8em;
    text-align: center;
}

.overlay {
    background: #00000070;
    border-top-left-radius: 22px;
}

.subject-list {
    max-height: 36em;
    overflow-x: hidden;
    overflow: auto;
    left: -3em !important;
    & div {
        display: flex;
        padding: 0.5em;
    }
}

.subject-dropdown {
    max-height: 35em;
    overflow: auto;
}

.dynamicHtmlClass {
    & img {
        width: 100%;
    }
}

.card-footer {
    background-color: #e6f0ff !important;
}

.subject-dropdown .dropdown-item {
    background: $bg-light;
    font-size: 1.875em;
    border-bottom: 0.063em solid #b7d6ff;
    padding: 0.625em 0.313em;
    color: $bg-blue;
    & .show {
        height: 10em;
        text-align: left;
    }
}

.workbook-year-label {
    background: $bg-blue;
    font-size: 1.25em;
    padding: 0.438em 2.563em;
    color: white;
    font-weight: 500;
    border-bottom-left-radius: 6.25em;
    border-bottom-right-radius: 6.25em;
}

.workbook-year-label:hover {
    color: white;
}

.workbook-year-dropdown .dropdown-item {
    background: $bg-light;
    font-size: 1.25em;
    border-bottom: 0.063em solid #b7d6ff;
    padding: 0.625em 0.313em;
    color: $bg-blue;
    width: 13.125em;
    text-align: center;
}

.rounded-xl {
    border-radius: 0.6rem;
}

.nav-preview {
    width: max-content;
}

.tab-sumary {
    font-weight: 600;
    color: #676869;
    border: 1px solid #e3f4fe;
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;
    margin-right: 0.5em;
    & .active {
        background: #e3f4fe !important;
        color: var(--label-color-blue);
    }
}

// catatan===
.videoThumbContainer {
    min-height: 12em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: max-content; */
    width: 100%;
    // box-sizing: content-box;
}
.playClass {
    position: absolute;
    cursor: pointer;
    transition: 0.35s;
    &:hover {
        transform: scale(1.2);
        transition: 0.35s;
    }
}

.badge-preload {
    color: #676869;
    background-color: #cacbce;
}

// p {
// 	line-height: 1.7em;
// 	text-align: justify;
// }

.content-box {
    background: #fff;
    box-shadow: 0 3px 13px rgba(21, 121, 253, 0.1);
    border-radius: 0.9em;
    margin-bottom: 2.75rem;
}

ol {
    list-style-type: upper-alpha;
    text-align: justify;
    li {
        margin-bottom: 1em;
    }
}

ul {
    list-style-type: lower-roman;
    margin-left: 1em;
}

.positionRelative {
    position: relative;
}

.font-weight-bold {
    font-weight: 700;
}

.font-weight-semibold {
    font-weight: 600;
}
.font-weight-medium {
    font-weight: 500;
}
.text-label-dark-gray {
    color: $label-color-dark-gray;
}
.text-label-blue {
    color: var(--label-color-blue);
}
