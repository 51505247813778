html {
    scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: libre franklin, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px !important;
  /* overflow: hidden; */
}
