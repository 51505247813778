$bgColor: #f8f8f8;
$bg-content: rgba(221, 225, 227, 0);
$bg-icon: #a6b5b8;

$icon-active: #a6b5b8;

$icon-shaddow: 3px 3px 5px rgba(0, 0, 0, 0.1);

$icon-size: 39px;
$cirle-radius: 180px;
$borderColor: #c8c8c8;
$fillLightGray: #e2e2e2;

$themeBlue: #488bff;
$greenColor: #49eb9f;
$grayColor: #f2f2f2;
$fadedThemeColor: #7aabff;
$yellowColor: #ffd753;
$labelOne: #11a0be;
$labelTwo: #594ef5;
$labelThree: #56be11;
$labelFour: #eb6043;
$labelFive: #18f3a1;

$greenActionColor: #49ee8b;
$darkGreenColor: #03c62a;
$lightGrayActionColor: #ababab;

$white-color: #fff;
$border-color: #bbb;
$tripleSeventy: #707070;
$color-green: #00d215;
$color-white: #fff;
$Colorshadow: 0 0 3px rgba(0, 0, 0, 0.35);
$deepShadow: 0 3px 8px rgba(0, 0, 0, 0.16);
$lightShadow:-1px 1px 2px 0px rgba(78, 78, 78, 0.021);

$table-color: var(--themeBlue);
$striped-light: #ffffff;
$striped-dark: #f8f8f8;
$inputBoxBg: #f1f1f1;
$notificationColor: #6258ed;
$switchBaseColor: #dedede;
$arrowBg: #a4b6b8;
$accordianColorChapter: #e7eced;
$uploaderTextColor: #919191;
$announcementLightGray: #aeaeae;
$tripleAB: #a8a8a8;

// this is new color fallet for new themes

$bg-container: #f2f7ff;
$blue-primary: #034aa6;

// this is new color pallet for new themes

$bg-container: #f2f7ff;
$blue-primary: #034aa6;
$active: #4f92ea;
$line-header: #e3edfd;

$select-filter-border: #f7f8f8;

// BG color for empty state (Evaluation Format, Independent)
$bg-empty-state: #f2f7ff;


// left navigation style
$bg-icon: #4e90e7;

// color hover
$hover-color: #f2f7ff;
// color label
$border-color: #b7d6ff;
$label-color-orange: #feaf43;
$label-color-red: #f05974;
$label-color-blue-ocen: #36bfd7;
$label-color-gray: #bec4cf;
$label-color-blue: #4f92ea;
$label-color-green: #1abf73;
$label-color-purple: #7027a5;
$label-color-dark-gray: #676869;
$label-color-dark-gray-2: #232230;
$label-color-pink: #ff0578;
$label-color-dark-blue: #056cf2;

// =========================
// New Color Pallet===========

// Backgraund primary 
$bg-blue : #034AA6;
$bg-dark-blue : #204F8D;
$bg-light-blue : #056CF2; 
$bg-orange: #F24822;
$bg-light : #F2F7FF;
$bg-White : #FFFFFF;

// backgraund Secondary 
$bg-second-orange: #FEAF43;
$bg-second-red:#F05974;
$bg-second-blue:#36BFD7;
$bg-second-green: #1ABF73;
$bg-second-gray:#BEC4CF;
$bg-second-light:#F2F2F2;

// Light Color
$light-orange :#FFF0DE;
$light-green : #D2FBFC;
$light-dark-green: #E3F4FE ; 
$light-red : #FEF5F5;



$bg-shadow :0 0 3px rgba(0,0,0,0.35);

// 
